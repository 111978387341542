<template>
<div>
    <el-aside>
        <SideMenu :items='items'></SideMenu>
    </el-aside>
    <el-main>
        <router-view></router-view>
    </el-main>
</div>
</template>

<script>
import SideMenu from '@/components/common/SideMenu';
export default {
    components:{
        SideMenu
    },
    data(){
        return {
            items: [
                {
                    icon:"el-icon-document",
                    index: 'test2-1',
                 
                    title: '评估报告'
                },
                
        ],
        }
    },
}
</script>

<style scoped>

</style>